






































// @ is an alias to /src

import { defineComponent, PropType, watch } from '@vue/composition-api';
import { IRadios } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IRadios>,
      required: true,
    },
    value: Array,
  },
  setup(props, { emit }) {
    emit('input', props.config.options);
    watch(
      () => props.config,
      () => {
        emit('input', props.config.options);
      },
    );

    const updateValue = ($target: HTMLInputElement, i: number): void => {
      const { options } = props.config;
      if ($target.value === 'on') {
        $target.value = 'off';
      } else {
        $target.value = 'on';
      }
      options[i].checked = $target.value === 'on';
      if (props.config.onChange) {
        props.config.onChange(options);
      }
      emit('input', options);
    };
    return { updateValue };
  },
});
