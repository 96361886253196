






















// @ is an alias to /src

import { defineComponent, PropType } from '@vue/composition-api';
import { IDescription } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IDescription>,
      required: true,
    },
  },
});
